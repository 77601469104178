import { useEffect } from "react";
import { useNavigate } from "react-router";


const IIMProcess = () => {

  const loggedIn = localStorage.getItem('token')
  const navigate = useNavigate()



  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="Generator" content="Microsoft Word 15 (filtered)" />
      <link rel="stylesheet" href="../custom.css" />
      <div className="WordSection1">
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>IIM Admission 2023: Steps, Shortlisting & Final Admission Criteria</b>
        </p>
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          IIM Admission process 2023, started with CAT exam registration commencing on August 3 and closed on September 21, 2022. As high as 2.56 lac candidates applied for IIM Admission 2023. IIM Admission 2023 will have two more major steps – Shortlisting after declaration of CAT 2022 result and final IIM selection process that will begin after shortlisting process is over. CAT exam 2022 Result is expected in first week of January 2023 and immediately after this IIM’s will release their shortlist candidates list.
        </p>

        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>IIM Admissions 2023: Stages</b>
        </p>
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          IIM Admission process goes through following stages:
        </p>

        <table
          className="MsoTableGrid"
          style={{ borderCollapse: "collapse", border: "none" }}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "1.15in",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={110}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    IIM Admissions Stages
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "100.6pt",
                  border: "solid black 1pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={134}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    Key Components
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "1.5in",
                  border: "solid black 1pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={144}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    Time Lines & Process
                  </b>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "1.15in",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={110}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  CAT Registration & Application
                </p>
              </td>
              <td
                style={{
                  width: "100.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={134}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2.56 lac candidates have applied for CAT 2022
                </p>
              </td>
              <td
                style={{
                  width: "1.5in",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={144}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Last Date – 21stSep, 2022
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "1.15in",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={110}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Preliminary Screening
                </p>
              </td>
              <td
                style={{
                  width: "100.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={134}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Basis the Minimum CAT Score and Sectional Score
                </p>
              </td>
              <td
                style={{
                  width: "1.5in",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={144}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  As announced by individual IIM’s in its Admission Policy 2023
                </p>
              </td>
            </tr>

            <tr>
              <td
                style={{
                  width: "1.15in",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={110}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Shortlisting for PI Round
                </p>
              </td>
              <td
                style={{
                  width: "100.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={134}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Shortlisted basis CAT score, Profile, Work Experience and other components
                </p>
              </td>
              <td
                style={{
                  width: "1.5in",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={144}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Released after CAT Result
                </p>
              </td>
            </tr>

            <tr>
              <td
                style={{
                  width: "1.15in",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={110}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Final Selection Round
                </p>
              </td>
              <td
                style={{
                  width: "100.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={134}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Process may include Written Ability Test
                  (WAT), Group Discussion (GD) and Personal Interviews (PI)
                </p>
              </td>
              <td
                style={{
                  width: "1.5in",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={144}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Conducted between Feb to April, 2023 individually by 11 Old & New IIMs. 9 new and baby IIMstake admission by a Common Admission Process.
                </p>
              </td>
            </tr>

            <tr>
              <td
                style={{
                  width: "1.15in",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={110}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Generation of Final Merit List
                </p>
              </td>
              <td
                style={{
                  width: "100.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={134}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Based on weightages awarded to all the components
                </p>
              </td>
              <td
                style={{
                  width: "1.5in",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={144}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Released in April/May 2023
                </p>
              </td>
            </tr>

            <tr>
              <td
                style={{
                  width: "1.15in",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={110}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Making Final Admission Offer
                </p>
              </td>
              <td
                style={{
                  width: "100.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={134}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Depending upon the number of seats, candidates are given admission offer
                </p>
              </td>
              <td
                style={{
                  width: "1.5in",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={144}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  IIMs sends admission offerand candidates need to pay admission fee in time to reserve their seat.
                </p>
              </td>
            </tr>

          </tbody>
        </table>

        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>IIM Admission: Final Selection Parameters & Weightages</b>
        </p>

        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          CAT score is reduced to a large extent in the final selection. This weightage considerably differs from the shortlisting stage in final IIM Selection and Admission Criteria. It is to be noted that it is not necessary that IIMs will offer admission to all the shortlisted candidates or even to candidates with high CAT percentile. A candidate needs to score high in all the parameters of final selection round to get final admission offer from IIMs
        </p>

        <table
          className="MsoTableGrid"
          style={{ borderCollapse: "collapse", border: "none" }}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "1.15in",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={110}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    Name of the IIM
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "100.6pt",
                  border: "solid black 1pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={134}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    Weightage % for CAT Score
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "1.5in",
                  border: "solid black 1pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={144}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    Weightage % for PI-WAT
                    & Other Components
                  </b>
                </p>
              </td>
            </tr>

            <tr>
              <td
                style={{
                  width: "1.15in",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={110}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  IIM Ahmedabad
                </p>
              </td>
              <td
                style={{
                  width: "100.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={134}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  25
                </p>
              </td>
              <td
                style={{
                  width: "1.5in",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={144}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    color: "rgb(5, 123, 241)",
                    padding: "0in 5.4pt 0in 5.4pt",
                    textDecoration: "underline 1px solid",
                  }}
                >
                  {/* {loggedIn ? <a href="pdfs/IIM Ahmedabad - Eligibility.docx" target="_blank">

                    Download Admission 2023 Criteria
                  </a> : ''} */}

                  {loggedIn ? (
                    <a href="pdfs/IIM Ahmedabad - Eligibility.docx" target="_blank">
                      Download Admission 2023 Criteria
                    </a>
                  ) : (
                    <span onClick={(() => navigate('/login'))}>Download Admission 2023 Criteria</span>
                  )}
                </p>
              </td>
            </tr>

            <tr>
              <td
                style={{
                  width: "1.15in",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={110}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  IIM Bangalore
                </p>
              </td>
              <td
                style={{
                  width: "100.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={134}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  25
                </p>
              </td>
              <td
                style={{
                  width: "1.5in",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={144}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    color: "rgb(5, 123, 241)",
                    padding: "0in 5.4pt 0in 5.4pt",
                    textDecoration: "underline 1px solid",
                  }}
                >
                  {/* <a href="pdfs/IIM Bangalore - Admission Process.pdf" target="_blank" download="IIM Bangalore-Admission Process.pdf">
                  
                    Download Admission 2023 Criteria
                  </a> */}
                  {loggedIn ? (
                    <a href="pdfs/IIM Bangalore - Admission Process.pdf" target="_blank" download="IIM Bangalore-Admission Process.pdf">
                      Download Admission 2023 Criteria
                    </a>
                  ) : (
                    <span onClick={(() => navigate('/login'))}>  Download Admission 2023 Criteria</span>
                  )}
                </p>
              </td>
            </tr>

            <tr>
              <td
                style={{
                  width: "1.15in",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={110}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  IIM Calcutta
                </p>
              </td>
              <td
                style={{
                  width: "100.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={134}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  30
                </p>
              </td>
              <td
                style={{
                  width: "1.5in",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={144}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    color: "rgb(5, 123, 241)",
                    padding: "0in 5.4pt 0in 5.4pt",
                    textDecoration: "underline 1px solid",
                  }}
                >
                  {/* <a href="pdfs/IIM - CALCUTTA.docx" target="_blank">
                    Download Admission 2023 Criteria
                  </a> */}


                  {loggedIn ? (
                    <a href="pdfs/IIM Bangalore - Admission Process.pdf" target="_blank" download="IIM Bangalore-Admission Process.pdf">
                      Download Admission 2023 Criteria
                    </a>
                  ) : (
                    <span onClick={(() => navigate('/login'))}>  Download Admission 2023 Criteria</span>
                  )}
                </p>
              </td>
            </tr>

            <tr>
              <td
                style={{
                  width: "1.15in",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={110}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  IIM Lucknow
                </p>
              </td>
              <td
                style={{
                  width: "100.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={134}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  30
                </p>
              </td>
              <td
                style={{
                  width: "1.5in",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={144}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    color: "rgb(5, 123, 241)",
                    padding: "0in 5.4pt 0in 5.4pt",
                    textDecoration: "underline 1px solid",
                  }}
                >
                  {/* <a href="pdfs/IIM Lucknow.pdf" target="_blank" download="IIM Lucknow.pdf">
                    Download Admission 2023 Criteria
                  </a> */}



                  {loggedIn ? (
                    <a href="pdfs/IIM Lucknow.pdf" target="_blank" download="IIM Lucknow.pdf">
                      Download Admission 2023 Criteria
                    </a>

                  ) : (
                    <span onClick={(() => navigate('/login'))}>  Download Admission 2023 Criteria</span>
                  )}
                </p>
              </td>
            </tr>

            <tr>
              <td
                style={{
                  width: "1.15in",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={110}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  IIM Indore
                </p>
              </td>
              <td
                style={{
                  width: "100.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={134}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  20
                </p>
              </td>
              <td
                style={{
                  width: "1.5in",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={144}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    color: "rgb(5, 123, 241)",
                    padding: "0in 5.4pt 0in 5.4pt",
                    textDecoration: "underline 1px solid",
                  }}
                >
                  {/* <a href="pdfs/IIM Indore.pdf" target="_blank" download="IIM Indore.pdf">
                    Download Admission 2023 Criteria
                  </a> */}

                  {loggedIn ? (
                    <a href="pdfs/IIM Indore.pdf" target="_blank" download="IIM Indore.pdf">
                      Download Admission 2023 Criteria
                    </a>

                  ) : (
                    <span onClick={(() => navigate('/login'))}>  Download Admission 2023 Criteria</span>
                  )}
                </p>
              </td>
            </tr>

            <tr>
              <td
                style={{
                  width: "1.15in",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={110}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  IIM Kozhikode
                </p>
              </td>
              <td
                style={{
                  width: "100.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={134}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  44
                </p>
              </td>
              <td
                style={{
                  width: "1.5in",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={144}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    color: "rgb(5, 123, 241)",
                    padding: "0in 5.4pt 0in 5.4pt",
                    textDecoration: "underline 1px solid",
                  }}
                >
                  {/* <a href="pdfs/IIM Kozhikode.pdf" target="_blank" download="IIM Kozhikode.pdf">
                    Download Admission 2023 Criteria
                  </a> */}



                  {loggedIn ? (
                    <a href="pdfs/IIM Kozhikode.pdf" target="_blank" download="IIM Kozhikode.pdf">
                      Download Admission 2023 Criteria
                    </a>

                  ) : (
                    <span onClick={(() => navigate('/login'))}>  Download Admission 2023 Criteria</span>
                  )}
                </p>
              </td>
            </tr>

            <tr>
              <td
                style={{
                  width: "1.15in",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={110}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  IIM Kashipur
                </p>
              </td>
              <td
                style={{
                  width: "100.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={134}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  33
                </p>
              </td>
              <td
                style={{
                  width: "1.5in",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={144}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    color: "rgb(5, 123, 241)",
                    padding: "0in 5.4pt 0in 5.4pt",
                    textDecoration: "underline 1px solid",
                  }}
                >
                  {/* <a href="pdfs/IIM Kashipur.pdf" target="_blank" download="IIM Kashipur.pdf">
                    Download Admission 2023 Criteria
                  </a> */}

                  {loggedIn ? (
                    <a href="pdfs/IIM Kashipur.pdf" target="_blank" download="IIM Kashipur.pdf">
                      Download Admission 2023 Criteria
                    </a>

                  ) : (
                    <span onClick={(() => navigate('/login'))}>  Download Admission 2023 Criteria</span>
                  )}
                </p>
              </td>
            </tr>

            <tr>
              <td
                style={{
                  width: "1.15in",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={110}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  IIM Raipur
                </p>
              </td>
              <td
                style={{
                  width: "100.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={134}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  35
                </p>
              </td>
              <td
                style={{
                  width: "1.5in",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={144}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    color: "rgb(5, 123, 241)",
                    padding: "0in 5.4pt 0in 5.4pt",
                    textDecoration: "underline 1px solid",
                  }}
                >
                  {/* <a href="pdfs/IIM Raipur.docx" target="_blank">
                    Download Admission 2023 Criteria
                  </a> */}

                  {loggedIn ? (
                    <a href="pdfs/IIM Raipur.docx" target="_blank">
                      Download Admission 2023 Criteria
                    </a>

                  ) : (
                    <span onClick={(() => navigate('/login'))}>  Download Admission 2023 Criteria</span>
                  )}
                </p>
              </td>
            </tr>

            <tr>
              <td
                style={{
                  width: "1.15in",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={110}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  IIM Ranchi
                </p>
              </td>
              <td
                style={{
                  width: "100.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={134}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  40
                </p>
              </td>
              <td
                style={{
                  width: "1.5in",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={144}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    color: "rgb(5, 123, 241)",
                    padding: "0in 5.4pt 0in 5.4pt",
                    textDecoration: "underline 1px solid",
                  }}
                >
                  {/* <a href="pdfs/IIM Ranchi.pdf" target="_blank" download="IIM Ranchi.pdf">
                    Download Admission 2023 Criteria
                  </a> */}



                  {loggedIn ? (
                    <a href="pdfs/IIM Ranchi.pdf" target="_blank" download="IIM Ranchi.pdf">
                      Download Admission 2023 Criteria
                    </a>

                  ) : (
                    <span onClick={(() => navigate('/login'))}>  Download Admission 2023 Criteria</span>
                  )}

                </p>
              </td>
            </tr>

            <tr>
              <td
                style={{
                  width: "1.15in",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={110}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  IIM Tiruchirappalli
                </p>
              </td>
              <td
                style={{
                  width: "100.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={134}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  50
                </p>
              </td>
              <td
                style={{
                  width: "1.5in",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={144}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    color: "rgb(5, 123, 241)",
                    padding: "0in 5.4pt 0in 5.4pt",
                    textDecoration: "underline 1px solid",
                  }}
                >
                  {/* <a href="pdfs/IIM Tiruchirappalli.pdf" target="_blank" download="IIM Tiruchirappalli.pdf">
                    Download Admission 2023 Criteria
                  </a> */}

                  {loggedIn ? (
                    <a href="pdfs/IIM Tiruchirappalli.pdf" target="_blank" download="IIM Tiruchirappalli.pdf">
                      Download Admission 2023 Criteria
                    </a>

                  ) : (
                    <span onClick={(() => navigate('/login'))}>  Download Admission 2023 Criteria</span>
                  )}
                </p>
              </td>
            </tr>

            <tr>
              <td
                style={{
                  width: "1.15in",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={110}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  IIM Udaipur
                </p>
              </td>
              <td
                style={{
                  width: "100.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={134}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  60
                </p>
              </td>
              <td
                style={{
                  width: "1.5in",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={144}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    color: "rgb(5, 123, 241)",
                    padding: "0in 5.4pt 0in 5.4pt",
                    textDecoration: "underline 1px solid",
                  }}
                >
                  {/* <a href="pdfs/IIM Udaipur.docx" target="_blank">
                    Download Admission 2023 Criteria
                  </a> */}


                  {loggedIn ? (
                    <a href="pdfs/IIM Udaipur.docx" target="_blank">
                      Download Admission 2023 Criteria
                    </a>

                  ) : (
                    <span onClick={(() => navigate('/login'))}>  Download Admission 2023 Criteria</span>
                  )}
                </p>
              </td>
            </tr>

            <tr>
              <td
                style={{
                  width: "1.15in",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={110}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  IIM Rohtak
                </p>
              </td>
              <td
                style={{
                  width: "100.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={134}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  60
                </p>
              </td>
              <td
                style={{
                  width: "1.5in",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={144}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    color: "rgb(5, 123, 241)",
                    padding: "0in 5.4pt 0in 5.4pt",
                    textDecoration: "underline 1px solid",
                  }}
                >
                  {/* <a href="pdfs/IIM Rohtak.pdf" target="_blank" download="IIM Rohtak.pdf">
                    Download Admission 2023 Criteria
                  </a> */}
                  {loggedIn ? (
                    <a href="pdfs/IIM Rohtak.pdf" target="_blank" download="IIM Rohtak.pdf">
                      Download Admission 2023 Criteria
                    </a>

                  ) : (
                    <span onClick={(() => navigate('/login'))}>  Download Admission 2023 Criteria</span>
                  )}
                </p>
              </td>
            </tr>

            <tr>
              <td
                style={{
                  width: "1.15in",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={110}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  IIM Amritsar
                </p>
              </td>
              <td
                style={{
                  width: "100.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={134}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  40
                </p>
              </td>
              <td
                style={{
                  width: "1.5in",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={144}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    color: "rgb(5, 123, 241)",
                    padding: "0in 5.4pt 0in 5.4pt",
                    textDecoration: "underline 1px solid",
                  }}
                >
                  {/* <a href="pdfs/IIM Amritsar.docx" target="_blank">
                    Download Admission 2023 Criteria
                  </a> */}
                  {loggedIn ? (
                    <a href="pdfs/IIM Amritsar.docx" target="_blank">
                      Download Admission 2023 Criteria
                    </a>

                  ) : (
                    <span onClick={(() => navigate('/login'))}>  Download Admission 2023 Criteria</span>
                  )}
                </p>
              </td>
            </tr>

            <tr>
              <td
                style={{
                  width: "1.15in",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={110}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  IIM Bodhgaya
                </p>
              </td>
              <td
                style={{
                  width: "100.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={134}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  40
                </p>
              </td>
              <td
                style={{
                  width: "1.5in",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={144}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    color: "rgb(5, 123, 241)",
                    padding: "0in 5.4pt 0in 5.4pt",
                    textDecoration: "underline 1px solid",
                  }}
                >
                  {/* <a href="pdfs/IIM Amritsar.docx" target="_blank">
                    Download Admission 2023 Criteria
                  </a> */}
                  {loggedIn ? (
                    <a href="pdfs/IIM Amritsar.docx" target="_blank">
                    Download Admission 2023 Criteria
                  </a>
                  ) : (
                    <span onClick={(() => navigate('/login'))}>  Download Admission 2023 Criteria</span>
                  )}
                </p>
              </td>
            </tr>

            <tr>
              <td
                style={{
                  width: "1.15in",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={110}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  IIM Amritsar
                </p>
              </td>
              <td
                style={{
                  width: "100.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={134}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  40
                </p>
              </td>
              <td
                style={{
                  width: "1.5in",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={144}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    color: "rgb(5, 123, 241)",
                    padding: "0in 5.4pt 0in 5.4pt",
                    textDecoration: "underline 1px solid",
                  }}
                >
                  {/* <a href="pdfs/IIM-Bodh-Gaya.pdf" target="_blank" download="IIM-Bodh-Gaya.pdf">
                    Download Admission 2023 Criteria
                  </a> */}
                  {loggedIn ? (
                    <a href="pdfs/IIM-Bodh-Gaya.pdf" target="_blank" download="IIM-Bodh-Gaya.pdf">
                      Download Admission 2023 Criteria
                    </a>
                  ) : (
                    <span onClick={(() => navigate('/login'))}>  Download Admission 2023 Criteria</span>
                  )}
                </p>
              </td>
            </tr>

            <tr>
              <td
                style={{
                  width: "1.15in",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={110}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  IIM Jammu
                </p>
              </td>
              <td
                style={{
                  width: "100.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={134}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  40
                </p>
              </td>
              <td
                style={{
                  width: "1.5in",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={144}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    color: "rgb(5, 123, 241)",
                    padding: "0in 5.4pt 0in 5.4pt",
                    textDecoration: "underline 1px solid",
                  }}
                >
                  {/* <a href="pdfs/IIM Jammu.docx" target="_blank">
                    Download Admission 2023 Criteria
                  </a> */}
                  {loggedIn ? (
                    <a href="pdfs/IIM Jammu.docx" target="_blank">
                      Download Admission 2023 Criteria
                    </a>
                  ) : (
                    <span onClick={(() => navigate('/login'))}>  Download Admission 2023 Criteria</span>
                  )}
                </p>
              </td>
            </tr>

            <tr>
              <td
                style={{
                  width: "1.15in",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={110}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  IIM Nagpur
                </p>
              </td>
              <td
                style={{
                  width: "100.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={134}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  15
                </p>
              </td>
              <td
                style={{
                  width: "1.5in",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={144}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    color: "rgb(5, 123, 241)",
                    padding: "0in 5.4pt 0in 5.4pt",
                    textDecoration: "underline 1px solid",
                  }}
                >
                  {/* <a href="pdfs/IIM Nagpur.docx" target="_blank">
                    Download Admission 2023 Criteria
                  </a> */}
                  {loggedIn ? (
                    <a href="pdfs/IIM Nagpur.docx" target="_blank">
                      Download Admission 2023 Criteria
                    </a>
                  ) : (
                    <span onClick={(() => navigate('/login'))}>  Download Admission 2023 Criteria</span>
                  )}
                </p>
              </td>
            </tr>

            <tr>
              <td
                style={{
                  width: "1.15in",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={110}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  IIM Sambalpur
                </p>
              </td>
              <td
                style={{
                  width: "100.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={134}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  30
                </p>
              </td>
              <td
                style={{
                  width: "1.5in",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={144}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    color: "rgb(5, 123, 241)",
                    padding: "0in 5.4pt 0in 5.4pt",
                    textDecoration: "underline 1px solid",
                  }}
                >
                  {/* <a href="pdfs/IIM SAMBALPUR.docx" target="_blank">
                    Download Admission 2023 Criteria
                  </a> */}
                  {loggedIn ? (
                    <a href="pdfs/IIM SAMBALPUR.docx" target="_blank">
                      Download Admission 2023 Criteria
                    </a>
                  ) : (
                    <span onClick={(() => navigate('/login'))}>  Download Admission 2023 Criteria</span>
                  )}
                </p>
              </td>
            </tr>

            <tr>
              <td
                style={{
                  width: "1.15in",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={110}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  IIM Sirmaur
                </p>
              </td>
              <td
                style={{
                  width: "100.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={134}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  30
                </p>
              </td>
              <td
                style={{
                  width: "1.5in",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={144}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    color: "rgb(5, 123, 241)",
                    padding: "0in 5.4pt 0in 5.4pt",
                    textDecoration: "underline 1px solid",
                  }}
                >
                  {/* <a href="pdfs/IIM Sirmaur.pdf" target="_blank" download="IIM Sirmaur.pdf">
                    Download Admission 2023 Criteria
                  </a> */}
                  {loggedIn ? (
                    <a href="pdfs/IIM Sirmaur.pdf" target="_blank" download="IIM Sirmaur.pdf">
                      Download Admission 2023 Criteria
                    </a>
                  ) : (
                    <span onClick={(() => navigate('/login'))}>  Download Admission 2023 Criteria</span>
                  )}
                </p>
              </td>
            </tr>

            <tr>
              <td
                style={{
                  width: "1.15in",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={110}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  IIM Visakhapatnam
                </p>
              </td>
              <td
                style={{
                  width: "100.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={134}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  25
                </p>
              </td>
              <td
                style={{
                  width: "1.5in",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={144}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    textAlign: "center",
                    marginBottom: "0in",
                    color: "rgb(5, 123, 241)",
                    padding: "0in 5.4pt 0in 5.4pt",
                    textDecoration: "underline 1px solid",
                  }}
                >
                  {/* <a href="pdfs/IIM Visakhapatnam.pdf" target="_blank" download="IIM Visakhapatnam.pdf">
                    Download Admission 2023 Criteria
                  </a> */}
                  {loggedIn ? (
                    <a href="pdfs/IIM Visakhapatnam.pdf" target="_blank" download="IIM Visakhapatnam.pdf">
                      Download Admission 2023 Criteria
                    </a>
                  ) : (
                    <span onClick={(() => navigate('/login'))}>  Download Admission 2023 Criteria</span>
                  )}
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>Admission in IIM: Check Your Eligibility</b>
        </p>
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          CAT is the mandatory entrance exam for admission in any of the 20 IIMs and other top MBA colleges like FMS Delhi, MDI Gurgaon, IMI New Delhi, NITIE Mumbai, IITs etc. IIM releases the CAT exam notification every year in July/August and the registration and application process for IIM-Common Admission Test (CAT) starts online in the first week of August. A candidate needs to apply for IIM admission test – CAT from August to September when the application process remains open.
        </p>

        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Along with the CAT notification, IIMs also launch official CAT exam website iimcat.ac.in. The website contains all the details about candidates’ eligibility requirement for CAT and for admission in IIMs including new IIMs admission process.
        </p>

        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          The eligibility for IIM Selection and Admission Criteria two aspects
        </p>

        <p
          className="cont mb-0"
          style={{
            marginLeft: "0.2in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        > 1.
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          Eligibility requirement to appear in CAT exam
        </p>

        <p
          className="cont mb-0"
          style={{
            marginLeft: "0.2in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        > 2.
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          Eligibility for particular IIM Admission
        </p>

        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Although there is not much difference between the minimum eligibility requirement to appear in CAT exam and to be considered for admission in IIMs, as both need minimum 50% marks in graduation, the weightages awarded to your CAT score, academic excellence and other parameters differ from one IIM to other IIM.
        </p>


        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>IIMs Shortlist Based on CAT Score</b>
        </p>
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          IIMs release the shortlist of candidates for PI round in the month of January. These shortlists for IIMs Admission with CAT cut offs are announced by each IIM from among the candidates who have a valid CAT score, who have applied to the programme and who satisfy the eligibility criteria for the IIM MBA programme.  While CAT cut offs in shortlisting are in the range of 80 to 98 percentile, other parameters like academic profile, diversity are also applied in shortlisting process for final selection round.
        </p>

        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>IIM Selection Process: Final Round</b>
        </p>
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Even if a candidate is shortlisted based on high CAT exam score, your admission chances in IIMs have only somewhat improved. You need to convert your IIM call into final admission which is based upon different weightages in final selection process. IIMs award varying weightages to the parameters like CAT score, Personal Interview, Work Experience and profile of the candidates before making final admission offer.
        </p>

        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>IIM Admission: Generation of Merit List</b>
        </p>
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          The final admission result is declared in the months of April / May on the basis of above parameters. As per the past trends, IIMs may offer admission at lower CAT exam score to the candidates who have excelled in various parts of life like working in social sector, villages; have diverse profile like painting, graphology, national level players, writers, scientists among others.
        </p>


        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>IIM Selection and Admission Criteria</b>
        </p>
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          IIM selection and admission criteria can be divided in two parts – Shortlisting by IIMs and Final selection process in IIMs. Both of these processes award different weightages to the components like CAT exam score, academics, work experience, diversity, Group Discussion/Written Ability Test and Personal Interview. The weightage for CAT score at shortlisting stage is much higher than the weightage awarded final selection round.
        </p>

        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>IIM Selection Process Changes</b>
        </p>
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >

          <u>
            CAT exam score Weightages Reduced:
          </u> &nbsp;
          In the admission process for IIMs, weightage to CAT score in shortlisting is reduced in a range of 50 to 75 percent on a scale of 100, to improve diversity. However, many new and Baby IIMs shortlist the candidates only on the basis of CAT score and award 100% weightage to CAT score
        </p>

        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >

          <u>
            CAT Cut off Percentile Reduced for Admission in IIMs:
          </u> &nbsp;
          During last 2-3 years, IIMs have reduced CAT cut offs to 70-80 sectional percentile and 80-90 overall percentile for admission.  Although Minimum cut off is announced between 80 to 95 percentile, actual cut off goes much higher. Below these cut off score, IIMs do not shortlist candidates for further rounds of admission. Not only overall score is important but the sectional score in CAT is equally important. For more information, check here the eligibility criteria of all the IIM’s.
        </p>


        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>New IIMs Admission: Through Common Admission Process (CAP)</b>
        </p>
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          The older IIMs conduct their admission process individually and award weightage to Academics, Diversity, and work experience at the shortlisting stage, 9 new IIMs admission process awards weightage to these parameters in final admission round. All these 9 new IIMs including IIM Kashipur, Raipur, Ranchi, Trichy, Udaipur, Bodhgaya, Jammu, Sirmaur, and Sambalpur have common shortlisting criteria followed by Common Admission Process (CAP) comprising PI-WAT. It is only after the CAP that each of these new IIMs generates its individual merit list based on its own parameters and weightages to make final admission offer.
        </p>

        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          IIM Rohtak, IIM Amritsar, IIM Nagpur, IIM Visakhapatnam conduct their own admission process, instead of participating in CAP.
        </p>

        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>IIM Admission Criteria</b>
        </p>
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          The details given above are about the different stages of IIMs Admission as reflected in their individual admission policy. The admission criteria contains the required cut offs in CAT exam, weightages awarded to different components in shortlisting and final admission round.
        </p>

        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>IIM Selection Process</b>
        </p>
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          The final selection process in IIMs start after the shortlisting is completed on the basis of CAT scores and other parameters. In the IIMs final selection process the weightage to CAT exam score is substantially reduced and higher weightage is awarded to PI-WAT, Academics, Work Experience and other parameters. Please note CAT selection and IIMs final selection are two different things and even high CAT scorers may not make it top IIMs.
        </p>


        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>Top Non-IIMs Admission</b>
        </p>
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Apart from IIMs, CAT scores are accepted by many top MBA colleges. FMS Delhi, SPJIMR Mumbai, MDI Gurgaon, XIM Bhubaneswar and other top MBA colleges accept CAT scores. MBA admission in these colleges is based on the basis of different national and state level MBA entrance exams.
        </p>
      </div>
    </div>
  );
};

export default IIMProcess;
