import CustomTable from "../../../CustomTable";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const NewIIMsProcessCAP = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="Generator" content="Microsoft Word 15 (filtered)" />
      <link rel="stylesheet" href="../custom.css" />
      <div className="WordSection1">
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>New IIMs CAP 2023: PI Dates, Shortlisting, Selection Process, Centres, Cut off etc.</b>
        </p>
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Common Admission Process or CAP is a selection process that is conducted by 9 new IIMs for admission into its MBA/PGP programme. The top IIMs in India conduct admission on the basis of the respective cutoff of each institute after the declaration of the CAT 2022 result. As part of CAP 2023, the candidates who qualify CAT will have to take part in CAP 2023 which consists of a Personal Interview (PI).In CAP 2023, Writing Ability Test (WAT) may not be conducted.
        </p>

        <b className="table-details">Table of content</b>

        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <a href="#what-is-IIM">
            What is IIM CAP 2023?
          </a>
        </p>

        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <a href="">	CAP Dates 2023</a>
        </p>

        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <a href="">	CAP Application Form</a>
        </p>

        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <a href="">	Registration Process for IIM CAP 2023</a>
        </p>

        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <a href="">IIM CAP 2023 Shortlisting Criteria</a>
        </p>

        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <a href="">CAP 2023 – Participation Process</a>
        </p>


        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <a href="">Steps in IIM CAP 2023 Process</a>
        </p>

        <p
          className="MsoListParagraphCxSpLast"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <a href="">
            CAP 2021 - Final WAT and PI Shortlisting Criteria
          </a>
        </p>

        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>


        <div id="what-is-IIM">
          <p
            className=" cont"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            <b>What is IIM CAP 2023?</b>
          </p>
          <p
            className=" cont"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            Common Admission Process or CAP process is a single-window selection process of the nine new IIMs in which candidates who are eligible for MBA admission 2023 will have to appear only for the single PI round. Under the IIM CAP process, participating IIMs send the list of candidates shortlisted for the PI process to IIM Trichy. A candidate should note that qualifying CAT 2022 cut off, admission parameters, and their weightage is common to all CAP participating IIMs.
          </p>

          <p
            className=" cont"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            The scores obtained by candidates in CAP 2023 are then shared with individual institutes. Based on the final score of candidates, the nine IIMs declare their own merit list of candidates selected for MBA admission. The final list of selected candidates is displayed on the individual institute's website. Only those candidates who have institute login can access the merit list.
          </p>

          <p
            className=" cont"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            &nbsp;
          </p>

          <p
            className=" cont"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            <b>CAP Dates 2023</b>
          </p>
          <p
            className=" cont"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            Candidates who have cleared CAT 2022 can check the schedule for IIM CAP 2023 mentioned below.
          </p>

          <table
            className="MsoTableGrid"
            style={{ borderCollapse: "collapse", border: "none" }}
            cellSpacing={0}
            cellPadding={0}
            border={1}
          >
            <tbody>
              <tr>
                <td
                  style={{
                    width: "1.15in",
                    border: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={110}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal",
                    }}
                    align="center"
                  >
                    <b>
                      S.No
                    </b>
                  </p>
                </td>
                <td
                  style={{
                    width: "100.6pt",
                    border: "solid black 1pt",
                    borderLeft: "none",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={134}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal",
                    }}
                    align="center"
                  >
                    <b>
                      Event
                    </b>
                  </p>
                </td>
                <td
                  style={{
                    width: "1.5in",
                    border: "solid black 1pt",
                    borderLeft: "none",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal",
                    }}
                    align="center"
                  >
                    <b>
                      Dates (Tentative)
                    </b>
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    width: "1.15in",
                    border: "solid black 1pt",
                    borderTop: "none",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={110}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      textAlign: "center",
                      marginBottom: "0in",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    1
                  </p>
                </td>
                <td
                  style={{
                    width: "100.6pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={134}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    Last date for CAP 2023 registration
                  </p>
                </td>
                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    Feb-23
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    width: "1.15in",
                    border: "solid black 1pt",
                    borderTop: "none",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={110}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    2
                  </p>
                </td>
                <td
                  style={{
                    width: "100.6pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={134}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    Intimation regarding Personal Interview (PI)
                  </p>
                </td>
                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    Feb-23
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    width: "1.15in",
                    border: "solid black 1pt",
                    borderTop: "none",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={110}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    3
                  </p>
                </td>
                <td
                  style={{
                    width: "100.6pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={134}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    Personal Interview Phase I
                  </p>
                </td>
                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    Feb-23
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    width: "1.15in",
                    border: "solid black 1pt",
                    borderTop: "none",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={110}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    4
                  </p>
                </td>
                <td
                  style={{
                    width: "100.6pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={134}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    Personal Interview Phase II
                  </p>
                </td>
                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    Feb-23
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    width: "1.15in",
                    border: "solid black 1pt",
                    borderTop: "none",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={110}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    5
                  </p>
                </td>
                <td
                  style={{
                    width: "100.6pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={134}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    Personal Interview Phase III
                  </p>
                </td>
                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    Mar-23
                  </p>
                </td>
              </tr>
            </tbody>
          </table>

          <p
            className=" cont"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            &nbsp;
          </p>

          <p
            className="headings"
            id="4"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            CAP Application Form
          </p>
          <p
            className="cont"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            A candidate needs the following documents ready before filling up the IIM CAP registration form 2023:
          </p>
          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            Soft copy of the Photograph and Signature (Less than 100 KB).
          </p>
          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            Photo Identity Card
          </p>
          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            CAT 2022 Scorecard
          </p>
          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            Class 10 and Class 12 mark sheet with a pass certificate
          </p>
          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            Graduation certificates
          </p>
          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            Experience certificates (Please do not consider training and apprenticeship as an experience)
          </p>

          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            Issue valid caste certificate (NC-OBC/ SC/ ST) as per the format given on the CAT website. (For NC-OBC, it is valid for the next six months from the date of registration)
          </p>

          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            In the case of DAP / PwD, issue the certificate following the format given on the CAT website.
          </p>

          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            In the case of EWS, issue the certificate following the format given on the CAT website. The certificate is valid for the year 2023-24 (based on income for the financial year 2022-23).
          </p>

          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            Extra-curricular and co-curricular certificates
          </p>

          <p
            className="MsoNormal"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            &nbsp;
          </p>

          <p
            className="headings"
            id="4"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            Registration Process for IIM CAP 2023
          </p>
          <p
            className="cont"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            Candidates who want to register for CAP 2023 are advised to follow the below steps:
          </p>
          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            To register for IIM CAP 2023, candidates have to visit the official website.
          </p>
          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            Find and click the link "Candidate Sign in".
          </p>
          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            Fill the CAP 2023 registration form with valid details.
          </p>
          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            Upload all the required documents in a prescribed format.
          </p>
          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            Finally, submit the filled IIM CAP application form 2023.
          </p>

          <p
            className="MsoNormal"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            &nbsp;
          </p>


          <p
            className=" cont"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            <b>IIM CAP 2023 Shortlisting Criteria</b>
          </p>
          <p
            className=" cont"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            The expected minimum cutoff of CAT percentile required in each category and the overall percentile for listing candidates for personal interview is listed below:
          </p>

          <table
            className="MsoTableGrid"
            style={{ borderCollapse: "collapse", border: "none" }}
            cellSpacing={0}
            cellPadding={0}
            border={1}
          >
            <tbody>
              <tr>
                <td
                  style={{
                    width: "1.15in",
                    border: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={110}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal",
                    }}
                    align="center"
                  >
                    <b>
                      Category
                    </b>
                  </p>
                </td>
                <td
                  style={{
                    width: "100.6pt",
                    border: "solid black 1pt",
                    borderLeft: "none",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={134}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal",
                    }}
                    align="center"
                  >
                    <b>
                      QA
                    </b>
                  </p>
                </td>
                <td
                  style={{
                    width: "1.5in",
                    border: "solid black 1pt",
                    borderLeft: "none",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal",
                    }}
                    align="center"
                  >
                    <b>
                      DILR
                    </b>
                  </p>
                </td>

                <td
                  style={{
                    width: "1.5in",
                    border: "solid black 1pt",
                    borderLeft: "none",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal",
                    }}
                    align="center"
                  >
                    <b>
                      VARC
                    </b>
                  </p>
                </td>

                <td
                  style={{
                    width: "1.5in",
                    border: "solid black 1pt",
                    borderLeft: "none",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal",
                    }}
                    align="center"
                  >
                    <b>
                      Total Percentile
                    </b>
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    width: "1.15in",
                    border: "solid black 1pt",
                    borderTop: "none",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={110}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      textAlign: "center",
                      marginBottom: "0in",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    General
                  </p>
                </td>
                <td
                  style={{
                    width: "100.6pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={134}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    70
                  </p>
                </td>
                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    70
                  </p>
                </td>

                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    70
                  </p>
                </td>

                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    92
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    width: "1.15in",
                    border: "solid black 1pt",
                    borderTop: "none",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={110}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    EWS
                  </p>
                </td>
                <td
                  style={{
                    width: "100.6pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={134}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    40
                  </p>
                </td>
                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    40
                  </p>
                </td>

                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    40
                  </p>
                </td>

                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    70
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    width: "1.15in",
                    border: "solid black 1pt",
                    borderTop: "none",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={110}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    NC-OBC
                  </p>
                </td>
                <td
                  style={{
                    width: "100.6pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={134}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    45
                  </p>
                </td>
                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    45
                  </p>
                </td>

                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    45
                  </p>
                </td>

                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >74
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    width: "1.15in",
                    border: "solid black 1pt",
                    borderTop: "none",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={110}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    SC
                  </p>
                </td>
                <td
                  style={{
                    width: "100.6pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={134}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    35
                  </p>
                </td>
                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    35
                  </p>
                </td>

                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    35
                  </p>
                </td>

                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    54
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    width: "1.15in",
                    border: "solid black 1pt",
                    borderTop: "none",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={110}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    ST
                  </p>
                </td>
                <td
                  style={{
                    width: "100.6pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={134}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    32.5
                  </p>
                </td>
                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    25
                  </p>
                </td>

                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    25
                  </p>
                </td>

                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    40
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    width: "1.15in",
                    border: "solid black 1pt",
                    borderTop: "none",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={110}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    DAP
                  </p>
                </td>
                <td
                  style={{
                    width: "100.6pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={134}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    32.5
                  </p>
                </td>
                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    25
                  </p>
                </td>

                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    25
                  </p>
                </td>

                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    40
                  </p>
                </td>
              </tr>
            </tbody>
          </table>


          <p
            className=" cont"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            &nbsp;
          </p>

          <p
            className="headings"
            id="4"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            CAP 2023 – Participation Process
          </p>
          <p
            className="cont"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            Instead of in person PI-WAT round, candidates would attend the CAP 2023 in online mode due to the Covid pandemic. There may be no Writing Ability Test (WAT) process in CAP 2023, the PI round will be conducted in online mode. Please note that you need to fulfill the following requirements for CAP personal interview round through online mode:
          </p>
          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            Desktop/Laptop with camera
          </p>
          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            Headphone/earphone
          </p>
          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            Microphone
          </p>
          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            Stable and high-speed Internet connection
          </p>
          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            Stationary items such as pens/pencils/blank sheet
          </p>
          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            Installation of Zoom software on Desktop/Laptop
          </p>

          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            A white background or a clear background of a lighter color ensuring better visibility
          </p>

          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            A valid photo ID for verification
          </p>

          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <u>
              Key Guidelines to participate in online Personal Interview
            </u>
          </p>

          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            The link for Online Personal Interview is sent to the candidate’s registered email ID two-three days prior to the interview
          </p>

          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            Candidates are required to join the Online Personal Interview through the Zoom platform by clicking on the provided link to attend the interview on the designated date and time of the interview
          </p>

          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            For Forenoon Slot: The candidate should join by 9.30 AM and should be available in the waiting room until his/her PI process is over
          </p>

          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            For Afternoon Slot: The candidate should join by 1.30 pm and should be available in the waiting room until his/her PI process is over
          </p>

          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            Candidates must be prepared for the interview with the necessary requirements as already detailed on the designated date and time
          </p>

          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            No person other than the candidate should be present in the interview room in the house/any other place
          </p>

          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            Recording devices are strictly prohibited in the interview room
          </p>

          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            The audio and video must be ‘on’ for the entire duration of the interview
          </p>

          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            Candidates should be available for the entire slot as communicated
          </p>

          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            Please check your inbox as well as SPAM or Junk or promotion boxes/folders regularly
          </p>

          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            The panel will evaluate the candidate’s academic efforts and disposition, attitude, personality and communication, and the quality of work experience.
          </p>

          <p
            className="MsoNormal"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            &nbsp;
          </p>


          <p
            className="headings"
            id="4"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            Steps in IIM CAP 2023 Process
          </p>
          <p
            className="cont"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            Candidates who clear CAT 2022 are eligible to take part in CAP 2023. The steps that are followed by the IIMs are:
          </p>
          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              1.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            All the candidates who meet the respective cutoff for the IIMs for which they have applied are shortlisted for the next round of the selection. Candidates are called from multiple IIMs for the next stage of selection if they meet the requisite cutoff of the IIM to which they had applied for admission.
          </p>
          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              2.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            All the IIMs which participate in CAP 2023 will send the list of shortlisted candidates to the CAP conducting IIM Trichy.
          </p>
          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              3.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            Once the IIMs have sent the list of candidates shortlisted for the next round of selection, IIM Trichy will prepare the final merit list and send the email informing the WAT-PI date and venue to students who confirmed participation in the admission round.
          </p>
          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              4.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            All the candidates who receive a call for CAP 2023 will have to take part in the personal interview round be it in online mode or in person at the campus.
          </p>
          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              5.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            Depending on the performance of the candidates in the personal interview, the decided IIM will prepare the final scores for the candidates who participate in CAP 2023. The scores are sent to the respective IIMs.
          </p>
          <p
            className=" cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              6.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            All the participating IIMs have their own admission policy and they provide weightage to the CAT score, applicant’s profile as well as CAP score before they make a final offer to the candidates. The offer letters are then sent to selected candidates via email.
          </p>


          <p
            className="MsoNormal"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            &nbsp;
          </p>


          <p
            className=" cont"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            <b>CAP 2021 - Final WAT and PI Shortlisting Criteria</b>
          </p>
          <p
            className=" cont"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            Candidates can check the previous year category wise cut off for CAP. This will give them an idea about the probable IIM CAP cutoff 2023.
          </p>

          <table
            className="MsoTableGrid"
            style={{ borderCollapse: "collapse", border: "none" }}
            cellSpacing={0}
            cellPadding={0}
            border={1}
          >
            <tbody>
              <tr>
                <td
                  style={{
                    width: "1.15in",
                    border: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={110}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal",
                    }}
                    align="center"
                  >
                    <b>
                      Category
                    </b>
                  </p>
                </td>
                <td
                  style={{
                    width: "100.6pt",
                    border: "solid black 1pt",
                    borderLeft: "none",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={134}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal",
                    }}
                    align="center"
                  >
                    <b>
                      Data Interpretation &
                      Logical Reasoning

                    </b>
                  </p>
                </td>
                <td
                  style={{
                    width: "1.5in",
                    border: "solid black 1pt",
                    borderLeft: "none",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal",
                    }}
                    align="center"
                  >
                    <b>
                      Quantitative Aptitude
                    </b>
                  </p>
                </td>

                <td
                  style={{
                    width: "1.5in",
                    border: "solid black 1pt",
                    borderLeft: "none",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal",
                    }}
                    align="center"
                  >
                    <b>
                      Verbal & Reading
                      Comprehension

                    </b>
                  </p>
                </td>

                <td
                  style={{
                    width: "1.5in",
                    border: "solid black 1pt",
                    borderLeft: "none",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal",
                    }}
                    align="center"
                  >
                    <b>
                      Total Percentile
                    </b>
                  </p>
                </td>

                <td
                  style={{
                    width: "1.5in",
                    border: "solid black 1pt",
                    borderLeft: "none",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal",
                    }}
                    align="center"
                  >
                    <b>
                      Total candidates
                      Shortlisted

                    </b>
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    width: "1.15in",
                    border: "solid black 1pt",
                    borderTop: "none",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={110}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      textAlign: "center",
                      marginBottom: "0in",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    General
                  </p>
                </td>
                <td
                  style={{
                    width: "100.6pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={134}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    80 (80.40)
                  </p>
                </td>
                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    80 (80.35)
                  </p>
                </td>

                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                   80 (80.62)
                  </p>
                </td>

                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    95
                  </p>
                </td>

                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    6504
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    width: "1.15in",
                    border: "solid black 1pt",
                    borderTop: "none",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={110}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    NC-OBC
                  </p>
                </td>
                <td
                  style={{
                    width: "100.6pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={134}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    60(60.39)
                  </p>
                </td>
                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    60 (60.37)
                  </p>
                </td>

                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    60(60.45)
                  </p>
                </td>

                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    78 (78.01)
                  </p>
                </td>

                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    3461 
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    width: "1.15in",
                    border: "solid black 1pt",
                    borderTop: "none",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={110}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    SC
                  </p>
                </td>
                <td
                  style={{
                    width: "100.6pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={134}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    50 (51.67)
                  </p>
                </td>
                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    50 (50.75)
                  </p>
                </td>

                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    50 (51.06)
                  </p>
                </td>

                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    60 (60.01)
                  </p>
                </td>

                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    1742 
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    width: "1.15in",
                    border: "solid black 1pt",
                    borderTop: "none",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={110}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    ST
                  </p>
                </td>
                <td
                  style={{
                    width: "100.6pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={134}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    30 (30.16)
                  </p>
                </td>
                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    30 (31.54)
                  </p>
                </td>

                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    30 (30.60)
                  </p>
                </td>

                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    40 (40.01)
                  </p>
                </td>

                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    807 
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    width: "1.15in",
                    border: "solid black 1pt",
                    borderTop: "none",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={110}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                   DAP
                  </p>
                </td>
                <td
                  style={{
                    width: "100.6pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={134}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                   30 (30.16)
                  </p>
                </td>
                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    30 (31.54)
                  </p>
                </td>

                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    30 (30.60)
                  </p>
                </td>

                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    40 (40.16)
                  </p>
                </td>

                <td
                  style={{
                    width: "1.5in",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1pt",
                    borderRight: "solid black 1pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                  }}
                  width={144}
                  valign="top"
                >
                  <p
                    className=" cont"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      textJustify: "inter-ideograph",
                      lineHeight: "normal",
                    }}
                  >
                    276
                  </p>
                </td>
              </tr>
            </tbody>
          </table>

          <p
            className=" cont"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            &nbsp;
          </p>
        </div>
      </div>
    </div>
  );
};

export default NewIIMsProcessCAP;
